import Helper from './Helper';

class App {
  constructor() {
    this.button = document.querySelector('.nav');
    this.popup = document.querySelector('.pop-up');
    this.bindEvents();
  }

  bindEvents() {
      this.button.addEventListener('click', ()=> {
          if(!this.popup.classList.contains('active')) {
            Helper.addClass(this.popup, 'active');
            setTimeout(()=> {
                Helper.addClass(this.popup, 'active--ani');
            }, 300);
            Helper.addClass(this.button, 'active');
          }
        });

      window.addEventListener("click", (e) => {
        if(!e.target.classList.contains('.pop-up') && this.popup.classList.contains('active--ani')) {
          Helper.removeClass(this.popup, 'active--ani');
          setTimeout(()=> {
              Helper.removeClass(this.popup, 'active');
          }, 300);
          Helper.removeClass(this.button, 'active');
        }});
  }

}

window.onload = () => {
  new App();
} 